.modalmessage__textarea {
  border: none;
  color: var(--color-neutral-gray-dark);
  font-family: var(--font-family-plus-jakarta);
  font-size: var(--font-size-lg);
  height: 100%;
  outline: none;
  overflow: hidden;
  resize: none;
  width: 100%;
  position: relative;
}

.modalmessage__textarea--mobile {
  color: var(--color-alvi-neutral-black);
}

.modalmessage__textarea::placeholder {
  color: var(--color-neutral-gray-input);
  font-family: var(--font-family-plus-jakarta);
}

.modal_mobile {
  bottom: 0;
  position: absolute !important;
  overflow-x: hidden;
}

.container_modal {
  height: 100% !important;
  flex: 1;
}

.name {
  text-transform: lowercase;
  display: block;
}

.name::first-letter {
  text-transform: uppercase;
}
