.modalConfirmation__overflow {
    overflow: initial;
}
.modalConfirmation__container{ 
    background-color: transparent!important;
}

@media screen and (max-width: 39.9375em) {
    .modalConfirmation__overflow {
        bottom: 0;
        position: absolute !important;
        overflow-x: hidden;
        border-radius: var(--border-radius-md) var(--border-radius-md) 0 0!important;
    }
}

.modalConfirmation__icon {
    display: flex;
    justify-content: center;
    left: 0;
    top: -30px;
}