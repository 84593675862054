.term-and-conditions__container > article {
  overflow: hidden !important;
}

.term-and-conditions__container > article > article > div:nth-child(2) {
  max-height: 350px !important;
}

.term-and-conditions__container--desktop > article {
  min-height: 465px !important;
  min-width: 832px !important;
}

.term-and-conditions__container--mobile {
  overflow: hidden;
}

.term-and-conditions__container--mobile > article {
  min-height: 100% !important;
  height: 100% !important;
}

.term-and-conditions__container--mobile > article > article {
  height: 100% !important;
}

.term-and-conditions__container--mobile > article > article > div:nth-child(2) {
  min-height: calc(100% - 190px) !important;
}
