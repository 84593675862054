@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('/fonts/PlusJakartaSans-VariableFont_wght.ttf');
  font-display: block;
  font-style: normal;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family:
    'Plus Jakarta Sans',
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.animateTooltip {
  animation: opacity 3s ease-out;
}

.no-scroll {
  overflow: hidden !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

@keyframes opacity {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
